export interface GetNotificationsResponse {
    totalPages: number;
    totalElements: number;
    size: number;
    content: NotificationResponse[];
    number: number;
    sort: {
        empty: boolean,
        sorted: boolean;
        unsorted: boolean;
    };
    pageable: {
        offset: number;
        sort: {
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
        },
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    };
    numberOfElements: number;
    empty: boolean;
    first: boolean;
    last: boolean;
}

export interface NotificationResponse {
    id: number,
    command: string,
    topic: string,
    userId: number,
    createdDate: string,
    payload: SendNotificationBodyType[],
}

export enum NotificationTypes {
    COMMON = 'common',
    PERSONAL = 'personal',
}

export enum NotificationPushTypes {
    FIREBASE = 'FIREBASE',
    WEBSOCKET = 'WEBSOCKET',
}


export const NotificationTypesFilter = [
    {
        name: 'Общие',
        command: NotificationTypes.COMMON
    },
    {
        name: 'Персональные',
        command: NotificationTypes.PERSONAL
    },
];

export const NotificationPushTypesFilter = [
    {
        name: 'FIREBASE',
        command: NotificationPushTypes.FIREBASE
    },
    {
        name: 'WEBSOCKET',
        command: NotificationPushTypes.WEBSOCKET
    },
];

export interface SendNotificationBodyType {
    licensePlates?: string[],
    typeEnum: string,
    headerKk?: string,
    headerRu?: string,
    headerEn?: string,
    messageKk?: string,
    messageRu?: string,
    messageEn?: string
}
