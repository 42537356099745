export interface GetEventsResponse {
    totalPages: number;
    totalElements: number;
    size: number;
    content: EventType[];
    number: number;
    sort: {
    empty: boolean,
            sorted: boolean;
            unsorted: boolean;
    };
    pageable: {
        offset: number;
            sort: {
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
        },
        pageNumber: number;
        pageSize: number;
        paged: boolean;
        unpaged: boolean;
    };
    numberOfElements: number;
    empty: boolean;
}

export enum EventStatus {
    DRAFT = 'DRAFT',
    CREATED = 'CREATED',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    IN_PROGRESS = 'IN_PROGRESS',
    PROCESSED = 'PROCESSED',
}

export const EventStatusDetails = {
    [EventStatus.CREATED]: {
        title: 'Создано',
        status: EventStatus.CREATED,
        style: 'btn-tbl-map-secondary',
    },
    [EventStatus.DRAFT]: {
        title: 'Принято',
        status: EventStatus.DRAFT,
        style: 'btn-tbl-draft-secondary',
    },
    [EventStatus.PROCESSED]: {
        title: 'Исполнено',
        status: EventStatus.PROCESSED,
        style: 'btn-tbl-confirm-secondary',
    },
    [EventStatus.IN_PROGRESS]: {
        title: 'В работе',
        status: EventStatus.IN_PROGRESS,
        style: 'btn-tbl-in-progress-secondary',
    },
    [EventStatus.REJECTED]: {
        title: 'Отклонено',
        status: EventStatus.REJECTED,
        style: 'btn-tbl-reject-secondary',
    },
};

export const EventStatusSpecialistList = [
    {
        status: EventStatusDetails[EventStatus.PROCESSED].status,
        title: EventStatusDetails[EventStatus.PROCESSED].title
    },
    {
        status: EventStatusDetails[EventStatus.REJECTED].status,
        title: EventStatusDetails[EventStatus.REJECTED].title
    },
]

export const EventStatusForFilter = [
    {status: EventStatus.DRAFT, name: EventStatusDetails[EventStatus.DRAFT].title},
    {status: EventStatus.PROCESSED, name: EventStatusDetails[EventStatus.PROCESSED].title},
    {status: EventStatus.REJECTED, name: EventStatusDetails[EventStatus.REJECTED].title},
    {status: EventStatus.IN_PROGRESS, name: EventStatusDetails[EventStatus.IN_PROGRESS].title},
];

export enum EventCategory {
    CAR_ACCIDENT = 'CAR_ACCIDENT',
    ANIMAL_ON_ROAD = 'ANIMAL_ON_ROAD',
    FOREIGN_OBJECTS_ON_ROAD = 'FOREIGN_OBJECTS_ON_ROAD',
    BAD_WEATHER_CONDITIONS = 'BAD_WEATHER_CONDITIONS',
    EMERGENCY_STATE_OF_ROAD = 'EMERGENCY_STATE_OF_ROAD',
}

export const EventCategoryName = {
    [EventCategory.CAR_ACCIDENT]: 'ДТП',
    [EventCategory.ANIMAL_ON_ROAD]: 'Выход животного на дорогу',
    [EventCategory.FOREIGN_OBJECTS_ON_ROAD]: 'Посторонние предметы на дороге',
    [EventCategory.BAD_WEATHER_CONDITIONS]: 'Плохие погодные условия или их последствия',
    [EventCategory.EMERGENCY_STATE_OF_ROAD]: 'Аварийное состояние дороги или инфраструктуры',
};

export interface UserInfoType {
    userId: number;
    fio: string;
    email: string;
    phone: string;
}

export interface EventFileType {
    uid: string;
    fileName: string;
    contentType: string;
    fileSize: number;
    fileDesc: string;
    fileHash: string;
    fileUser: string;
    containerClass: string;
    containerId: number;
    documentCategory: string;
    storageType: string;
    uploadDateTime: string;
}

export interface BitrixResponseDtoType {
    sapar_tx: string;
    event_id: number;
    resolution_text: string;
    status: EventStatus;
    employee: string;
    empl_phone: string;
    empl_rod: string;
    empl_email: string;
    file_links: string;
}

export interface EventType {
    gid: number;
    category: EventCategory;
    messageKk: string;
    messageRu: string;
    messageEn: string;
    descriptionKk: string;
    descriptionRu: string;
    descriptionEn: string;
    status: EventStatus;
    formOpenDate: string;
    sendDate: string;
    formOpenSatelliteDate: string;
    sendSatelliteDate: string;
    selectedVehicle: string;
    userId: number;
    formOpenPoint: string;
    sendPoint: string;
    userInfo: UserInfoType;
    files: EventFileType[];
    kato: string;
    bitrixResponseDto: BitrixResponseDtoType[];
}

export interface RegionType {
    id: number;
    code: string;
    nameKz: string;
    nameRu: string;
    nameEn: string;
    descriptionKz: string;
    descriptionRu: string;
    descriptionEn: string;
}

export const SupervisorList = {
    "190000000": [
        {empl_email: "", employee: 'Усенов Асылбек Жарилхасинович'},
        {empl_email: "", employee: 'Нурымов Даурен Нурболатович'},
        {empl_email: "", employee: 'Ажыбаев Жаркын Бекетович'},
        {empl_email: "aktanov@qaj.kz", employee: 'Актанов Арман Серикович'},
        {empl_email: "", employee: 'Баймырза Абай Талғатович'},
        {empl_email: "", employee: 'Какежанов Маргулан Дуйсенович'},
        {empl_email: "", employee: 'Сатылған Олжас Берікұлы'},
    ],
    "710000000": [
        {empl_email: "samezov.d@qaj.kz", employee: 'Самезов Данияр Султанович'},
    ],
    "610000000": [
        {empl_email: "", employee: 'Керимкулов Н.'},
        {empl_email: "nakhipov@qaj.kz", employee: 'Нахипов Е.'},
    ],
};

export const SpecialistList = {
    "190000000": [
        {empl_email: "ikhiev@qaj.kz", employee: 'Ихиев Ермек Джаннатович'},
        {empl_email: "", employee: 'Нұрғазин Ержан Ерханович'},
        {empl_email: "yesembaev@qaj.kz", employee: 'Есембаев Дастан Сагымбаевич'},
    ],
    "710000000": [
        {empl_email: "syzdykov.m@qaj.kz", employee: 'Сыздыков Марат Берикович'},
    ],
    "610000000": [
        {empl_email: "", employee: 'Жунисбеков Б.'},
        {empl_email: "elemesov@qaj.kz", employee: 'Елемесов Р.'},
    ],
};

export interface SendEventStatusType {
    sapar_tx: string,
    event_id: number,
    resolution_text: string,
    status: EventStatus,
    employee: string,
    empl_phone: string,
    empl_email: string,
    empl_rod: string,
    file_links: string
}

export interface EventUploadFileType {
    url: string;
    name: string;
}
