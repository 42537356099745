const ApiConfigAdminLayersApi = 'https://sapar.kaztoll.kz/adm-layers';
const ApiConfigGlobalApi = 'https://sapar.kaztoll.kz/global-search';
const ApiConfigAdminUsersApi = 'https://sapar.kaztoll.kz/adm-users';
const ApiConfigUserDataApi = 'https://sapar.kaztoll.kz/user-data';
const ApiConfigEventsApi = 'https://sapar.kaztoll.kz/events';
const ApiConfigDictionariesApi = 'https://sapar.kaztoll.kz/dictionaries';
const ApiConfigFileStorageApi = 'https://sapar.kaztoll.kz/file-storage';


// const ApiConfigAdminLayersApi = 'http://192.168.122.132/adm-layers';
// const ApiConfigGlobalApi = 'http://192.168.122.132/global-search';
// const ApiConfigAdminUsersApi = 'http://192.168.122.132/adm-users';
// const ApiConfigUserDataApi = 'http://192.168.122.132/user-data';
// const ApiConfigEventsApi = 'http://192.168.122.132/events';
// const ApiConfigDictionariesApi = 'http://192.168.122.132/dictionaries';
// const ApiConfigFileStorageApi = 'http://192.168.122.132/file-storage';


export const ApiConfigLayer = {
  // api: '/adm-layers',
  api: ApiConfigAdminLayersApi,
  client_id: 'geoportal',
  client_secret: 'geoportal',
};
export const ApiConfigGlobalSearch = {
  // api: '/global-search',
  api: ApiConfigGlobalApi,
  client_id: 'geoportal',
  client_secret: 'geoportal',
};
export const ApiConfigUsers = {
  // api: '/adm-users',
  api: ApiConfigAdminUsersApi,
  client_id: 'geoportal',
  client_secret: 'geoportal',
};
export const ApiConfigUserData = {
  // api: '/user-data',
  api: ApiConfigUserDataApi,
  client_id: 'user-data',
  client_secret: 'user-data',
};
export const ApiConfigEvents = {
  // api: '/events',
  api: ApiConfigEventsApi,
  client_id: 'user-data',
  client_secret: 'user-data',
};
export const ApiConfigDictionaries = {
  // api: '/dictionaries',
  api: ApiConfigDictionariesApi,
  client_id: 'dictionaries',
  client_secret: 'dictionaries',
};
export const ApiConfigFileStorage = {
  // api: '/file-storage',
  api: ApiConfigFileStorageApi,
  client_id: 'file-storage',
  client_secret: 'file-storage',
};
